<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { ARTICLES_LINK } from '@/modules/profile-page/constants/Links';
import { useUserStore } from '@/modules/profile-page/stores/UserStore';
import { useArticleStore } from '@/shared/stores/ArticleStore';
import { useBaseLawyerStore } from '@/shared/stores/BaseLawyerStore';
import { MAX_LENGTH_ARTICLES } from '@/modules/profile-page/constants/MaxLength';
import { GA_ATRIBUTES } from '@/shared/constants/GoogleAnalytics';
import { useGoogleAnalytics } from '@/shared/composables/googleAnalytics';
import { storeToRefs } from 'pinia';
import { TranslationHelper } from '@/shared/helper/TranslationHelper';
import { useProfileProductId } from '@/shared/stores/ProfileProductId';

const { getTranslationByProductId, getGenderValueForTranslation } = TranslationHelper();

const { t } = useI18n();
const { articleList } = useArticleStore();
const { uri, displayedName } = storeToRefs(useUserStore());

const { profile } = useBaseLawyerStore();
const { profileProductId } = useProfileProductId();

const { updateAttributeGA, dataAttributesGA } = useGoogleAnalytics();

updateAttributeGA({
  [GA_ATRIBUTES.ACTION]: t('eventActionClickLegalTipsRead'),
});
</script>

<template>
  <div v-if="articleList.length > MAX_LENGTH_ARTICLES" class="text-center">
    <a
      class="font-semibold text-info-500"
      :href="uri + ARTICLES_LINK"
      :title="`${getTranslationByProductId('articlesShowMoreTitle', profileProductId, {
        n: getGenderValueForTranslation(profile.sex),
      })} ${displayedName}`"
      v-bind="dataAttributesGA"
      >{{ t('articlesShowMore') }}</a
    >
  </div>
</template>
