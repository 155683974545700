<script setup lang="ts">
import { useNavbar } from '@/modules/profile-page/composables/useNavbar';

const { scrollToSection, availableSections, currentSection, stickyNav, navClasses } = useNavbar();
</script>

<template>
  <nav ref="stickyNav" class="sticky top-0 bg-white border-b border-neutral-300" :class="navClasses">
    <ol class="anw-column-full flex gap-x-5 whitespace-nowrap px-4 md:px-0 overflow-x-auto">
      <li v-for="section in availableSections" :key="section.id">
        <a
          :href="'#' + section.id"
          @click.prevent="scrollToSection(section.id)"
          class="block text-base font-semibold !no-underline py-3 px-1 relative focus:outline-none focus-visible:ring focus-visible:ring-inset"
          :class="[
            section.id === currentSection
              ? '!text-info-500 before:block before:absolute before:bottom-0 before:w-full border-b-2 border-info-500'
              : '!text-neutral-500 hover:!text-info-500',
          ]"
          >{{ section.label }}</a
        >
      </li>
    </ol>
  </nav>
</template>
