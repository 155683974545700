<script setup lang="ts">
import Languages from '@/modules/profile-page/components/About/Languages.vue';
import InternationalRight from '@/modules/profile-page/components/About/InternationalRight.vue';
import Certificates from '@/modules/profile-page/components/About/Certificates.vue';
import CarouselImages from '@/modules/profile-page/components/About/CarouselImages.vue';
import AboutMeVideo from '@/modules/profile-page/components/Video/AboutMeVideo.vue';
import Preamble from '@/modules/profile-page/components/About/Preamble.vue';
import AboutHeader from '@/modules/profile-page/components/About/AboutHeader.vue';
import CompanyLawyers from '../Company/CompanyLawyers.vue';
import { NAVBAR_ITEMS } from '@/modules/profile-page/constants/Navbar';
import Associations from '@/modules/profile-page/components/About/Associations.vue';
import { useProfileProductId } from '@/shared/stores/ProfileProductId';
import { COMPANY } from '@shared/constants/ProfileProductId';

const { profileProductId } = useProfileProductId();

const components = [
  AboutHeader,
  Preamble,
  CompanyLawyers,
  CarouselImages,
  AboutMeVideo,
  Certificates,
  Languages,
  InternationalRight,
  Associations,
];
</script>

<template>
  <section :id="NAVBAR_ITEMS.ABOUT" class="anw-profile-card lg:rounded-xl">
    <component
      v-for="(component, index) in components"
      :key="index"
      :is="component"
      :show-company-lawyers="profileProductId === COMPANY"
      class="first:border-t-0 first:pt-0 py-4 border-t border-neutral-200" />
  </section>
</template>
