<script setup lang="ts">
import { AnwLink } from '@anwalt.de/tailwind-design-system';
import { useI18n } from 'vue-i18n';
import { useArticleStore } from '@/shared/stores/ArticleStore';
import { useGoogleAnalytics } from '@/shared/composables/googleAnalytics';
import { GA_ATRIBUTES } from '@/shared/constants/GoogleAnalytics';
import { AnwReadMoreExternal } from '@anwalt.de/tailwind-design-system';
import { DateHelper } from '@/shared/helper/DateHelper';

const { articleList } = useArticleStore();
const { t } = useI18n();

const { formatDate } = DateHelper();
const { updateAttributeGA, dataAttributesGA } = useGoogleAnalytics();

updateAttributeGA({
  [GA_ATRIBUTES.ACTION]: t('eventActionClickLegalTips'),
});
</script>
<template>
  <ul class="grid gap-4 py-4">
    <li v-for="article in articleList" :key="article.id" class="bg-neutral-50 p-5 rounded-xl text-sm/[22px] relative">
      <div class="flex flex-col lg:flex-row justify-between gap-y-1 gap-x-4 mb-2">
        <p class="text-base font-semibold mb-0">{{ article.title }}</p>
        <span :datetime="formatDate(article.displayDate)" class="shrink-0 text-neutral-700">{{
          formatDate(article.displayDate)
        }}</span>
      </div>
      <AnwReadMoreExternal
        :text="article.shortDescription"
        :show-follow-link="false"
        class="line-clamp-2 text-sm text-neutral-700" />
      <div class="mt-4">
        <AnwLink
          class="font-semibold"
          v-bind="dataAttributesGA"
          :href="article.uri"
          :label="t('articleReadMore')"
          :srLabelExtension="article.title"
          :title="article.title"
          stretch />
      </div>
    </li>
  </ul>
</template>
